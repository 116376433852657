import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'lista-itens', pathMatch: 'full' },  
  //{ path: 'home', loadChildren: './pages/home/home.module#HomePageModule' },
  { path: 'lista-itens', loadChildren: './pages/lista-itens/lista-itens.module#ListaItensPageModule' },
  { path: 'item-detalhe', loadChildren: './pages/item-detalhe/item-detalhe.module#ItemDetalhePageModule' },
  { path: 'item-detalhe/:id', loadChildren: './pages/item-detalhe/item-detalhe.module#ItemDetalhePageModule' },
  { path: 'carrinho-detalhe', loadChildren: './pages/carrinho-detalhe/carrinho-detalhe.module#CarrinhoDetalhePageModule' },
  { path: 'pagamento', loadChildren: './pages/pagamento/pagamento.module#PagamentoPageModule' },
  { path: 'pagamento-cartao', loadChildren: './pages/pagamento-cartao/pagamento-cartao.module#PagamentoCartaoPageModule' },
  { path: 'local-prompt', loadChildren: './pages/local-prompt/local-prompt.module#LocalPromptPageModule' },
  { path: 'cpf-prompt', loadChildren: './pages/cpf-prompt/cpf-prompt.module#CpfPromptPageModule' },
  { path: 'cpf-input', loadChildren: './pages/cpf-input/cpf-input.module#CpfInputPageModule' },
  { path: 'local-consumo', loadChildren: './pages/local-consumo/local-consumo.module#LocalConsumoPageModule' },
  { path: 'resumo-pedido-final', loadChildren: './pages/resumo-pedido-final/resumo-pedido-final.module#ResumoPedidoFinalPageModule' },
  { path: 'fechar-pedidos', loadChildren: './pages/fechar-pedidos/fechar-pedidos.module#FecharPedidosModule' },
  { path: 'fechar-pedidos-resumo', loadChildren: './pages/fechar-pedidos-resumo/fechar-pedidos-resumo.module#FecharPedidosResumoModule' },
]; 
 
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
