// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  firebaseConfig : {
    apiKey: 'AIzaSyBVKPeUUmjAXW6jsW7a84tvp-7_kkkaxs4',
    authDomain: 'nbchef-26f53.firebaseapp.com',
    databaseURL: 'https://nbchef-26f53.firebaseio.com',
    projectId: 'nbchef-26f53',
    storageBucket: 'nbchef-26f53.appspot.com',
    messagingSenderId: '175127597299',
    appId: '1:175127597299:web:123c06fc11450a2f'
  }
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
